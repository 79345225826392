import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Theme, themeOptions } from '@commonsku/styles';
import { getPopups } from '../selectors';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import DashboardView from '../components/DashboardView';
import DashboardTitle from '../components/DashboardTitle';
import { reducers } from '../store/configureProjectDashboardStore';
import withReducers from '../store/withReducers';

import { createNewFeaturePopup } from '../actions/popup';
import { oauth } from '../utils';
import { createGlobalStyle } from '../components/helpers';
import { useHasCapabilities } from '../hooks';

export const GlobalStyle = createGlobalStyle(p => `
.resku body {
  font-family: "skufont-regular","skufont-demibold","museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
`);

const ProjectDashboardApp = (props) => {
  const { user_id, onCreateNewFeaturePopup, popups } = props;

  useEffect(() => {
    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if(json.show_popup == 1) {
        onCreateNewFeaturePopup();
      }
    });
  }, [ user_id, onCreateNewFeaturePopup ]);

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle theme={themeOptions} />
      <div>
        <Header>
          <DashboardTitle />
        </Header>

        <MainSection popups={popups}>
          <DashboardView hasUserGroups={useHasCapabilities(['HAS-USER-GROUPS'])} hasSalesTargets={useHasCapabilities(['HAS-SALES-TARGETS'])}/>
        </MainSection>

        <Overlay popups={popups} />
      </div>
    </Theme>
  );
};

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  popups: getPopups(state),
  user_id: state.identity.user_id
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    },
  };
};

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(ProjectDashboardApp), reducers);
