import { get } from 'lodash';
import React from 'react';

export const getOrderStatusClassName = (order_type, status_name) => "status-progress " + (order_type || '').toLowerCase().replace(' ', '-') + " " + (status_name || '').toLowerCase().replace(' ', '-');

export const ProjectProgressClient = ({ row, style={} }) =>
  <div style={style}><b>{get(row.original, ['client_name']) || ''}</b></div>;

export function ProjectProgressName({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={{
      ...style,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      height: 70,
    }}>
      <b>
        <a
          href={`/project/${j.job_number}`}
          rel="noreferrer"
          target="_blank"
          onClick={e => e.stopPropagation()}
        >{j.job_name}</a>
      </b>
      <br />
    </div>
  );
}

export function ProjectProgressIdeation({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <div
        data-html="true"
        data-tip={"<strong>" + j.order_type + "</strong><br>" + j.status_name + (typeof j.date_inhandsdate !== "undefined" ? "<br><br>In-Hands: " + j.date_inhandsdate.split(" ")[0] : "")}
        className={getOrderStatusClassName(j.order_type || '', j.status_name || '')}
      ></div>
    </div>
  );
}
