import _ from 'lodash';
import React from 'react';
import { CalendarTask, colors } from '@commonsku/styles';
import Avatar from '../../../helpers/Avatar';
import { getImageSrc, parseMysqlDate, toTimestamp, toTitleCase, isObj } from '../../../../utils';
import { isNoteComplete } from '../../../../redux/notes';

export function getLatestReminder(j) {
  const reminders = Object.values(_.get(j, ['reminder_list'], {}) || {})
    .filter(r => r.reminder_complete != 1);
  return _.first(reminders.sort(
    (a, b) => toTimestamp(a.date_reminder) - toTimestamp(b.date_reminder)
  )) || null;
}

export function getNextTaskValue(r) {
  return `${_.get(r, ['reminder_user_first_name']) || ''} ${_.get(r, ['reminder_user_last_name']) || ''}`.trim();
}

export function getTaskDateValue(r) {
  return (_.get(r, ['date_reminder']) || '').trim();
}

export function ProjectTaskClientRep({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <Avatar
        backgroundColor={colors.teal.main}
        name={j.client_rep_name || 'Client Rep'}
        data-tip={j.client_rep_name || 'Client Rep'}
        {...(isObj(j.client_rep_file)
          ? { src: getImageSrc(j.client_rep_file) }
          : {})}
      />
    </div>
  );
}

export function ProjectTaskOrderRep({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <Avatar
        backgroundColor={j.sales_rep_id !== j.client_rep_id ? colors.teal[70] : colors.teal.main}
        name={j.sales_rep_name || 'Order Rep'}
        data-tip={j.sales_rep_name || 'Order Rep'}
        style={{ paddingRight: 0, }}
        {...(isObj(j.sales_rep_file)
          ? { src: getImageSrc(j.sales_rep_file) }
          : {})}
      />
      {/* <b>{j.sales_rep_name}</b> */}
    </div>
  );
}

export function ProjectTaskName({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <b>
        <a href={`/project/${j.job_number}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >{`#${j.job_number} - ${j.job_name}`}</a>
      </b><br />
      {j.client_name}
    </div>
  );
}

export function ProjectTaskStage({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <b>{toTitleCase(j.order_type)}</b>
      <br />
      {j.status_name}
    </div>
  );
}

export function ProjectNextTask({
  row,
  style = {},
}) {
  const j = row.original;
  const r = getLatestReminder(j);
  const title = getNextTaskValue(r) || `Project #${j.job_number} Task`;
  const completed = isNoteComplete(r);
  return (
    <div style={{
      ...style,
      height: 95,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}>
      <CalendarTask
        style={{
          padding: 5,
          margin: 0,
          height: '100%',
        }}
        hideCheckbox
        date={undefined}
        title={<span>
          <Avatar
            name={title}
            style={{ display: 'inline-flex', fontSize: '0.85rem', height: 28, width: 28 }}
            data-html={"true"}
            data-tip={`<span style="padding: 10;">${title}</span>`}
            {...(isObj(j.reminder_user_file)
              ? { src: getImageSrc(j.reminder_user_file) }
              : {})}
          />
          <span style={{ paddingLeft: 5 }}>{title}</span>
        </span>}
        completed={completed}
        checked={completed}
        description={_.get(r, ['message_text'], '')}
        isDescriptionHtml
        id={"reminder-task-" + _.get(r, ['note_id'], '')}
      />
    </div>
  );
}

export function ProjectTaskDate({
  row,
  style = {},
}) {
  const j = row.original;
  const r = getLatestReminder(j);
  return (
    <div style={style}>
      {parseMysqlDate(getTaskDateValue(r))}
    </div>
  );
}
