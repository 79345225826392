import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import moment from 'moment';
import api from '../middleware/api';

import {
    CREATE_SALES_TARGETS_SUCCESS,
    FETCH_CONTACT_LIST_SUCCESS,
    FETCH_PHONE_LIST_SUCCESS, LOAD_DEPARTMENT_LIST_SUCCESS, LOAD_INDUSTRIES_SUCCESS,
    UPDATE_SALES_TARGET_SUCCESS,
} from '../actions';
import { UPDATE_ORDER_SUCCESS } from '../actions/order';
import {
  ADD_MESSAGE_SUCCESS,
  FETCH_REMINDERS_SUCCESS,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_NOTE_SUCCESS,
  UPDATE_REMINDER_SUCCESS,
} from '../actions/message';
import {
  FETCH_DASHBOARD_PROJECTS_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
} from '../actions/project';
import { formatMoney, parseMysqlDate, toTimestamp } from '../utils';
import {
  analyticsLoadingReducer,
  clientReducer,
  targetsManagementReducer,
  industryReducer,
  addressReducer,
    tagReducer,
  departmentReducer,
} from '../reducers/core';
import displayReducer from '../reducers/display';
import {
  bookmarksReducer,
  notificationCountReducer,
  notificationsReducer,
  recentOrdersReducer,
  remindersReducer,
} from '../reducers/header';
import { window } from '../global';
import { ADD_CLIENT_SUCCESS, LOAD_CLIENT_LIST_SUCCESS } from '../actions/client';
import { CREATE_CONTACT_SUCCESS, LOAD_COMPANY_CONTACT_LIST_SUCCESS } from '../actions/contact';

var initialState =
  typeof initialState === 'undefined' ? window.initialState : initialState;

const contactDropdownReducer = (state, action) => {
    switch (action.type) {
        case FETCH_CONTACT_LIST_SUCCESS: {
            let account_id = action.payload.account_id;
            let contacts = action.payload.contacts;
            return Object.assign({}, state, { [account_id]: contacts.map(c => c.contact_id) });
        }
        case LOAD_COMPANY_CONTACT_LIST_SUCCESS: {
            let company_id = action.payload.company_id;
            let company_contacts = action.payload.contacts;
            return Object.assign({}, state, { [company_id]: company_contacts.map(c => c.contact_id) });
        }
        case CREATE_CONTACT_SUCCESS: {
            let { contact_id, company_id } = action.payload.contact;
            return Object.assign({}, state, { [company_id]: [...state[company_id], contact_id] });
        }
    }

    return state;
};

const clientDropdownReducer = (state, action) => {
    switch (action.type) {
        case LOAD_CLIENT_LIST_SUCCESS:
            let clients = action.payload.clients;
            return clients.map(c => c.client_id);
        case ADD_CLIENT_SUCCESS:
            let client = action.payload.client;
            return [...state, client.client_id];
    }

    return state;
};

const industriesDropdownReducer = (state, action) => {
    switch (action.type) {
        case LOAD_INDUSTRIES_SUCCESS:
            return action.payload.industries.map(i => i.industry_id);
    }

    return state;
};

const departmentDropdownReducer = (state, action) => {
    switch (action.type) {
        case LOAD_DEPARTMENT_LIST_SUCCESS:
            return action.payload.departments;
    }

    return state;
};

const dropdownReducer = (state = initialState.dropdowns, action) => {
    return Object.assign({}, state, {
      contacts: contactDropdownReducer(state.contacts, action),
      clients: clientDropdownReducer(state.clients, action),
      industries: industriesDropdownReducer(state.industries, action),
        departments: departmentDropdownReducer(state.departments, action),
    });
};

const identityReducer = (state = initialState.identity, action) => {
    return state;
};

const jobReducer = (state, action) => {
    let message_id;
    let note;
    let job_id;
    let notes;
    let data;
    let reminder;
    let list;
    let job;

    switch (action.type) {
        case UPDATE_REMINDER_SUCCESS:
            if(!action.payload.id) {
                return state;
            }

            message_id = action.payload.id;
            note = action.payload.note;
            job_id = note.parent_id;

            reminder = Object.assign({}, state[job_id]['reminder_list'][message_id], {
                message_text: note.message_text,
                reminder_user: note.reminder_user,
                date_reminder: note.date_reminder,
                reminder_complete: note.reminder_complete
            });
            list = Object.assign({}, state[job_id]['reminder_list'], { [message_id]: reminder });
            return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], { reminder_list: list })  });
        case FETCH_REMINDERS_SUCCESS:
            if (!action.payload.id) {
                return state;
            }

            job_id = action.payload.id;
            notes = action.payload.notes;

            let updated_list = notes.reduce((o, note) => { o[note.message_id] = note; return o; }, {});
            return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], { reminder_list: updated_list }) });
        case UPDATE_NOTE_SUCCESS:
            message_id = action.payload.id;
            job_id = action.payload.parent_id;
            data = action.payload.data;

            reminder = Object.assign({}, state[job_id]['reminder_list'][message_id], data);
            list = Object.assign({}, state[job_id]['reminder_list'], { [message_id]: reminder });
            return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], { reminder_list: list }) });
        case UPDATE_MESSAGE_SUCCESS:
            job_id = action.payload.job_id;
            message_id =action.payload.parent_id;
            reminder = Object.assign({}, state[job_id]['reminder_list'][message_id], {pinned:action.payload.pinned});
            list = Object.assign({}, state[job_id]['reminder_list'], { [message_id]: reminder });
            return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], { reminder_list: list  }) });
        case ADD_MESSAGE_SUCCESS:
            note = action.payload.message;
            message_id = note.message_id;
            job_id = action.payload.job_id;

            list = Object.assign({}, state[job_id]['reminder_list'], {
                [message_id]: {
                    message_id: message_id,
                    note_id: note.note_id,
                    message_text: note.message_text,
                    date_reminder: note.date_reminder,
                    reminder_user: note.reminder_user.user_id,
                    reminder_complete: note.reminder_complete,
                    date_complete: note.date_complete,
                    reminder_user_first_name: note.reminder_user.user_first_name,
                    reminder_user_last_name: note.reminder_user.user_last_name
                }
            });
            return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], { reminder_list: list }) });
        case UPDATE_PROJECT_SUCCESS:
            job_id = action.payload.id;
            data = action.payload.data;
            job = action.payload.project;

            if(data.sales_rep_id) {
                return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {...data, sales_rep_name: `${job.sales_rep_first_name} ${job.sales_rep_last_name}` }) });
            }else if(data.client_rep_id) {
                return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {...data, client_rep_name: `${job.client_rep_first_name} ${job.client_rep_last_name}` }) });
            }else {
                return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], data) });
            }
        case FETCH_DASHBOARD_PROJECTS_SUCCESS:
            let jobs = action.payload.jobs;

            return jobs;
        case FETCH_PROJECT_SUCCESS:
            job_id = action.payload.job_id;
            return {
                ...state,
                [job_id]: {
                    ...(state[job_id] || {}),
                    ...action.payload.project,
                },
            };
        case UPDATE_ORDER_SUCCESS:
            job_id = action.payload.job_id;
            data = action.payload.data;

            if(data.date_inhandsdate) {
                let result = parseMysqlDate(data.date_inhandsdate, '0000-00-00', true);
                result = result.substring(0, result.indexOf(' '));

                var today = new Date();
                var overdue = toTimestamp(new Date(today.getFullYear(), today.getMonth(), 1));
                if (toTimestamp(data.date_inhandsdate) < overdue) {
                    return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {...data, month_inhandsdate: result, future: 0, overdue: 1}) });
                }

                var future = moment(new Date(today.getFullYear(), today.getMonth(), 1)).add(3, 'months').unix();
                if (toTimestamp(data.date_inhandsdate) >= future) {
                    return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {...data, month_inhandsdate: result, future: 1, overdue: 0}) });
                }

                return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {...data, month_inhandsdate: result, future: 0, overdue: 0}) });
            }

            return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], data) });
    }

    return state;
};

const contactReducer = (state, action) => {
    switch (action.type) {
        case FETCH_CONTACT_LIST_SUCCESS:
        case LOAD_COMPANY_CONTACT_LIST_SUCCESS:
            let contacts = action.payload.contacts;
            let list = contacts.reduce((o, c) => { o[c.contact_id] = c; return o;}, {});

            return Object.assign({}, state, list);
        case CREATE_CONTACT_SUCCESS:
            let contact = action.payload.contact;
            return Object.assign({}, state, { [contact.contact_id]: contact });
    }

    return state;
};

const phoneReducer = (state, action) => {
    switch (action.type) {
        case FETCH_PHONE_LIST_SUCCESS:
            let phones = action.payload.phones;
            let list = phones.reduce((o, p) => { o[p.phone_id] = p; return o; }, {});

            return Object.assign({}, state, list);
    }

    return state;
};

const salesTargetReducer = (state, action) => {
    let sales_targets;
    let user_id;

    switch (action.type) {
        case FETCH_DASHBOARD_PROJECTS_SUCCESS:
            sales_targets = action.payload.sales_targets;
            return sales_targets;
        case CREATE_SALES_TARGETS_SUCCESS:
            user_id = action.payload.id;
            sales_targets = action.payload.sales_targets;

            let targets = sales_targets.reduce((o, t) => { t.sales_target_value = formatMoney(t.sales_target_value); o[t.sales_target_id] = t; return o;}, {});
            return Object.assign({}, state, { [user_id]: Object.assign({}, state[user_id], targets) });
        case UPDATE_SALES_TARGET_SUCCESS:
            let sales_target_id = action.payload.id;
            let sales_target = action.payload.sales_target;
            user_id = sales_target.user_id;

            let target = Object.assign({}, state[user_id][sales_target_id], { sales_target_value: formatMoney(action.payload.sales_target.sales_target_value) });
            return Object.assign({}, state, { [user_id]: Object.assign({}, state[user_id], { [sales_target_id]: target }) });
    }

    return state;
};

const entitiesReducer = (state = initialState.entities, action) => {
    const new_state = Object.assign({}, state, {
        jobs: jobReducer(state.jobs, action),
        contacts: contactReducer(state.contacts, action),
        phones: phoneReducer(state.phones, action),
        sales_targets: salesTargetReducer(state.sales_targets, action),
        bookmarks: bookmarksReducer(state.bookmarks, action),
        recent_orders: recentOrdersReducer(state.recent_orders, action),
        reminders: remindersReducer(state.reminders, action),
        notifications: notificationsReducer(state.notifications, action),
        notification_count: notificationCountReducer(state.notification_count, action),
        targets_management:  targetsManagementReducer(state.targets_management,action),
        loading_analytics_popup: analyticsLoadingReducer (state.loading_analytics_popup,action),
        clients: clientReducer(state.clients, action),
        industries: industryReducer(state.industries, action),
        addresses: addressReducer(state.addresses, action),
        tags: tagReducer(state.tags, action),
        departments: departmentReducer(state.departments, action),
    });
    return new_state;
};

export const reducers = {
    display: displayReducer,
    dropdowns: dropdownReducer,
    entities: entitiesReducer,
    identity: identityReducer,
};

const rootReducer = combineReducers(reducers);

export default function configureProjectDashboardStore (initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}
