import React from 'react';
import { NewProjectPopupButton } from './project/NewProjectPopup';

export default function DashboardTitle() {
  return (
    <div className="row columns full-width" style={{ padding: '0 0 16px 0' }}>
      <div className="small-8 columns">
        <h4 style={{ display: 'inline-block' }}>
          <span>Projects</span>
        </h4>
      </div>
      <div className="small-4 columns">
        {/* this used to be an action menu with only one option to create new project */}
        <NewProjectPopupButton style={{float: 'right'}} />
      </div>
    </div>
  );
}
