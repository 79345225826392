import React from 'react';
import Avatar from '../../../helpers/Avatar';
import { formatDashboardMoney, getImageSrc, toTitleCase, isObj } from '../../../../utils';
import { colors } from '@commonsku/styles';

const subtotal_style = {
  textAlign: 'right',
  color: '#00D374'
};
const budget_style = {
  textAlign: 'right'
};
export const canUseSubtotal = (j) => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE');
export function getSalesMonthValue(month, j) {
  const use_subtotal = canUseSubtotal(j);
  return (
    !Number(j.overdue) && use_subtotal && j.month_inhandsdate === month
      ? formatDashboardMoney(j.total_subtotal)
      : !Number(j.overdue) && !use_subtotal && j.month_inhandsdate === month
        ? formatDashboardMoney(j.total_budget)
        : ''
  );
}
export function getSalesOverdueValue(j) {
  const use_subtotal = canUseSubtotal(j);
  return (
    Number(j.overdue) && use_subtotal
      ? formatDashboardMoney(j.total_subtotal)
      : Number(j.overdue) && !use_subtotal
        ? formatDashboardMoney(j.total_budget)
        : ''
  );
}

export function ProjectSalesClientRep({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <Avatar
        backgroundColor={colors.teal.main}
        name={j.client_rep_name || 'Client Rep'}
        data-tip={j.client_rep_name || 'Client Rep'}
        {...(isObj(j.client_rep_file)
          ? { src: getImageSrc(j.client_rep_file) }
          : {})}
      />
    </div>
  );
}

export function ProjectSalesOrderRep({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <Avatar
        backgroundColor={j.sales_rep_id !== j.client_rep_id ? colors.teal[70] : colors.teal.main}
        name={j.sales_rep_name || 'Order Rep'}
        data-tip={j.sales_rep_name || 'Order Rep'}
        style={{ paddingRight: 0, }}
        {...(isObj(j.sales_rep_file)
          ? { src: getImageSrc(j.sales_rep_file) }
          : {})}
      />
    </div>
  );
}

export function ProjectSalesName({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={{
      ...style,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      height: 70,
    }}>
      <b>
        <a href={`/project/${j.job_number}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >{`#${j.job_number} - ${j.job_name}`}</a>
      </b><br />
      {j.client_name}
    </div>
  );
}

export function ProjectSalesStage({
  row,
  style = {},
}) {
  const j = row.original;
  return (
    <div style={style}>
      <b>{toTitleCase(j.order_type)}{j.num_orders > 1 && ` (${j.num_orders})`}</b>
      <br />
      {j.status_name}
    </div>
  );
}

export function ProjectSalesOverdue({
  row,
  style = {},
}) {
  const j = row.original;
  const use_subtotal = canUseSubtotal(j);
  return (
    <div style={{ ...style, ...(use_subtotal ? subtotal_style : budget_style) }}>
      <b>{getSalesOverdueValue(j)}
      </b>
    </div>
  );
}

export function ProjectSalesByMonth({
  monthIdx = 0,
  row,
  column,
  style = {},
}) {
  const j = row.original;
  const use_subtotal = canUseSubtotal(j);
  const months = column.months || [];
  return (
    <div style={{ ...style, ...(use_subtotal ? subtotal_style : budget_style) }}>
      <b>{getSalesMonthValue(months[monthIdx], j)}</b>
    </div>
  );
}

export const ProjectSalesMonthOne = (props) => <ProjectSalesByMonth {...props} monthIdx={0} />;
export const ProjectSalesMonthTwo = (props) => <ProjectSalesByMonth {...props} monthIdx={1} />;
export const ProjectSalesMonthThree = (props) => <ProjectSalesByMonth {...props} monthIdx={2} />;
