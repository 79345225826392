import _ from 'lodash';
import React, { useCallback } from 'react';
import { Col, Row, SimpleWindowedTable, SimpleWindowedTableStyles } from '@commonsku/styles';
import {
  getSalesMonthValue,
  getSalesOverdueValue,
  getLatestReminder,
  getNextTaskValue,
  getTaskDateValue,
  // Sales
  ProjectSalesClientRep,
  ProjectSalesMonthOne,
  ProjectSalesMonthThree,
  ProjectSalesMonthTwo,
  ProjectSalesName,
  ProjectSalesOrderRep,
  ProjectSalesOverdue,
  ProjectSalesStage,
  ProjectNextTask,
  // Tasks
  ProjectTaskClientRep,
  ProjectTaskDate,
  ProjectTaskName,
  ProjectTaskOrderRep,
  ProjectTaskStage,
  // Progress
  ProjectProgressClient,
  ProjectProgressIdeation,
  ProjectProgressName,
} from './cols';
import useWindowSize from '../../../hooks/useWindowSize';
import { rebuildTooltip } from '../../helpers';
import { formatDashboardMoney, formatMoney } from '../../../utils';

const MonthColFooter = ({
  footerContainerStyle,
  footerTotalStyle,
  salesTargetStyle,
  salesTargets,
  monthIdx,
  value,
}) => (
  <div style={footerContainerStyle}>
    <div style={footerTotalStyle}>{formatDashboardMoney(value)}</div>
    {salesTargets.length ?
      <div
        style={salesTargetStyle}
        title={formatMoney((value / _.get(salesTargets, [monthIdx, 'sales_target_value'])) * 100, 0) + '%'}
      >{formatDashboardMoney(_.get(salesTargets, [monthIdx, 'sales_target_value']))}</div>
      : null}
  </div>
);

export default function ProjectTableWindowRow({
  jobs,
  type,
  months,
  sales_targets,
  search_query,
  client_rep_id,
  overdue,
  first,
  second,
  third,

  handleOpenProjectMessagePopups,
}) {
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const containerRef = React.useRef();

  const getListContainerHeight = React.useCallback(() => {
    let rectNode = {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
    };
    if (containerRef.current) {
      rectNode = containerRef.current.getBoundingClientRect();
    }

    return windowHeight - ((rectNode.x || 190) + (type === 'sales' ? 160 : 120)) - 10;
  }, [windowHeight, containerRef, type]);

  const columns = React.useMemo(() => {
    const salesTargets = !sales_targets[client_rep_id] ? [] :
      Object.values(sales_targets[client_rep_id]).slice(0, 3);
    const defaultStyle = { background: '#edf2f5' };

    if (type === 'task') {
      return [
        {
          Header: () => <div>Client Rep</div>,
          accessor: p => p.client_rep_name,
          sticky: 'left',
          Cell: ProjectTaskClientRep,
          noDrag: true,
          minWidth: 105,
          width: 105,
          maxWidth: 500,
          id: 'client_rep_id',
          style: defaultStyle,
        },
        {
          Header: () => <div>Order Rep</div>,
          accessor: p => p.sales_rep_name,
          sticky: 'left',
          Cell: ProjectTaskOrderRep,
          noDrag: true,
          minWidth: 105,
          width: 105,
          maxWidth: 500,
          id: 'sales_rep_id',
          style: defaultStyle,
        },
        {
          Header: () => <div>Project</div>,
          accessor: p => `${p.job_number}-${p.job_name} ${p.client_name}`,
          Cell: ProjectTaskName,
          sticky: 'left',
          noDrag: true,
          minWidth: 230,
          maxWidth: 500,
          id: 'job_id',
          style: defaultStyle,
        },
        {
          Header: () => <div>Stage</div>,
          accessor: p => `${p.order_type} ${p.status_id}`,
          Cell: ProjectTaskStage,
          sticky: 'left',
          noDrag: true,
          minWidth: 135,
          width: 135,
          maxWidth: 500,
          id: 'order_type',
          style: defaultStyle,
        },
        {
          Header: () => <div>Next Task</div>,
          accessor: j => getLatestReminder(getNextTaskValue(j)),
          Cell: ProjectNextTask,
          sticky: 'left',
          noDrag: true,
          minWidth: 300,
          maxWidth: 550,
          id: 'next_task',
          style: defaultStyle,
        },
        {
          Header: () => <div>Task Date</div>,
          accessor: j => getLatestReminder(getTaskDateValue(j)),
          Cell: ProjectTaskDate,
          sticky: 'left',
          noDrag: true,
          minWidth: 76,
          maxWidth: 500,
          id: 'task_date',
          style: defaultStyle,
        },
      ];
    } else if (type === 'progress') {
      return [
        {
          Header: () => <div>Client</div>,
          accessor: p => `${p.client_name}`,
          sticky: 'left',
          Cell: ProjectProgressClient,
          noDrag: true,
          minWidth: 100,
          maxWidth: 500,
          id: 'account_id',
          style: defaultStyle,
        },
        {
          Header: () => <div>Project</div>,
          accessor: p => `${p.job_name}-${p.job_number}`,
          Cell: ProjectProgressName,
          sticky: 'left',
          noDrag: true,
          minWidth: 180,
          maxWidth: 500,
          id: 'job_id',
          style: defaultStyle,
        },
        {
          Header: () => <Row style={{ display: 'flex' }}>
            <Col style={{ height: 40, paddingTop: 8, }} className="table-cell-sticky cursor-default arrow first">Ideation</Col>
            <Col style={{ height: 40, paddingTop: 8, }} className="table-cell-sticky cursor-default arrow second">Sales Order</Col>
            <Col style={{ height: 40, paddingTop: 8, }} className="table-cell-sticky cursor-default arrow third">In Production</Col>
            <Col style={{ height: 40, paddingTop: 8, }} className="table-cell-sticky cursor-default arrow last">Invoiced</Col>
          </Row>,
          Cell: ProjectProgressIdeation,
          sticky: 'left',
          noDrag: true,
          minWidth: 150 * 4,
          maxWidth: 500 * 4,
          id: 'ideation',
          style: {
            background: '#edf2f5',
            padding: 0,
          },
        },
      ];
    }

    const salesTargetStyle = {
      display: 'block',
      background: '#5CA3B6',
      color: '#fff',
      paddingTop: 5,
      paddingBottom: 5,
    };
    const footerTotalStyle = salesTargets.length ? {
      display: 'block',
      paddingTop: 5,
      paddingBottom: 5,
    } : {};
    const footerContainerStyle = {
      width: '100%',
      height: '100%',
    };

    return [
      {
        Header: () => <div>Client Rep</div>,
        accessor: p => p.client_rep_name,
        sticky: 'left',
        Cell: ProjectSalesClientRep,
        noDrag: true,
        minWidth: 105,
        width: 105,
        maxWidth: 500,
        id: 'client_rep_id',
        style: defaultStyle,
        className: salesTargets.length ? "project-col-client_rep" : '',
        Footer: () => <div style={footerContainerStyle}>
          <div style={footerTotalStyle}>&nbsp;</div>
          {salesTargets.length ?
            <div style={salesTargetStyle}>&nbsp;</div>
            : null}
        </div>,
      },
      {
        Header: () => <div>Order Rep</div>,
        accessor: p => p.sales_rep_name,
        sticky: 'left',
        Cell: ProjectSalesOrderRep,
        noDrag: true,
        minWidth: 105,
        width: 105,
        maxWidth: 500,
        id: 'sales_rep_id',
        style: defaultStyle,
        className: salesTargets.length ? "project-col-order_rep" : '',
        Footer: () => <div style={footerContainerStyle}>
          <div style={footerTotalStyle}>&nbsp;</div>
          {salesTargets.length ?
            <div style={salesTargetStyle}>&nbsp;</div>
            : null}
        </div>,
      },
      {
        Header: () => <div>Project</div>,
        accessor: p => `${p.job_number}-${p.job_name} ${p.client_name}`,
        Cell: ProjectSalesName,
        sticky: 'left',
        noDrag: true,
        minWidth: 250,
        maxWidth: 500,
        id: 'job_id',
        style: defaultStyle,
        className: salesTargets.length ? "project-col-name" : '',
        Footer: () => <div style={footerContainerStyle}>
          <div style={footerTotalStyle}>&nbsp;</div>
          {salesTargets.length ?
            <div style={salesTargetStyle}>&nbsp;</div>
            : null}
        </div>,
      },
      {
        Header: () => <div>Stage</div>,
        accessor: p => `${p.order_type} ${p.status_id}`,
        Cell: ProjectSalesStage,
        sticky: 'left',
        noDrag: true,
        minWidth: 135,
        width: 135,
        maxWidth: 500,
        id: 'order_type',
        style: defaultStyle,
        className: salesTargets.length ? "project-col-stage" : '',
        Footer: () => <div style={footerContainerStyle}>
          <div style={footerTotalStyle}>Total</div>
          {salesTargets.length ?
            <div style={salesTargetStyle}>Invoice Target</div>
            : null}
        </div>,
      },
      {
        Header: () => <div>Overdue</div>,
        Cell: ProjectSalesOverdue,
        accessor: j => {
          const value = getSalesOverdueValue(j).replace('$', '').replace(',', '').replace(' ', '').trim();
          if (!value) { return 0; }
          return parseFloat(value);
        },
        sticky: 'left',
        noDrag: true,
        minWidth: 110,
        width: 110,
        maxWidth: 500,
        id: 'overdue',
        style: { textAlign: 'right', background: '#edf2f5' },
        className: salesTargets.length ? 'project-col-overdue' : '',
        Footer: () => <div style={footerContainerStyle}>
          <div style={footerTotalStyle}>{formatDashboardMoney(overdue)}</div>
          {salesTargets.length ?
            <div style={salesTargetStyle}>&nbsp;</div>
            : null}
        </div>,
      },
      {
        Header: () => <div>{months[0]}</div>,
        accessor: j => {
          const value = getSalesMonthValue(months[0], j).replace('$', '').replace(',', '').replace(' ', '').trim();
          if (!value) { return 0; }
          return parseFloat(value);
        },
        Cell: ProjectSalesMonthOne,
        sticky: 'left',
        noDrag: true,
        minWidth: 110,
        width: 110,
        maxWidth: 500,
        id: 'month_1',
        months: months,
        style: { textAlign: 'right', background: '#edf2f5' },
        className: salesTargets.length ? 'project-col-month-1' : '',
        Footer: () => <MonthColFooter
          footerContainerStyle={footerContainerStyle}
          footerTotalStyle={footerTotalStyle}
          salesTargetStyle={salesTargetStyle}
          salesTargets={salesTargets}
          value={first}
          monthIdx={0}
        />,
      },
      {
        Header: () => <div>{months[1]}</div>,
        accessor: j => {
          const value = getSalesMonthValue(months[1], j).replace('$', '').replace(',', '').replace(' ', '').trim();
          if (!value) { return 0; }
          return parseFloat(value);
        },
        Cell: ProjectSalesMonthTwo,
        sticky: 'left',
        noDrag: true,
        minWidth: 110,
        width: 110,
        maxWidth: 500,
        id: 'month_2',
        months: months,
        style: { textAlign: 'right', background: '#edf2f5' },
        className: salesTargets.length ? 'project-col-month-2' : '',
        Footer: () => <MonthColFooter
          footerContainerStyle={footerContainerStyle}
          footerTotalStyle={footerTotalStyle}
          salesTargetStyle={salesTargetStyle}
          salesTargets={salesTargets}
          value={second}
          monthIdx={1}
        />,
      },
      {
        Header: () => <div>{months[2]}</div>,
        accessor: j => {
          const value = getSalesMonthValue(months[2], j).replace('$', '').replace(',', '').replace(' ', '').trim();
          if (!value) { return 0; }
          return parseFloat(value);
        },
        Cell: ProjectSalesMonthThree,
        sticky: 'left',
        noDrag: true,
        minWidth: 110,
        width: 110,
        maxWidth: 500,
        id: 'month_3',
        months: months,
        style: { textAlign: 'right', background: '#edf2f5' },
        className: salesTargets.length ? 'project-col-month-3' : '',
        Footer: () => <MonthColFooter
          footerContainerStyle={footerContainerStyle}
          footerTotalStyle={footerTotalStyle}
          salesTargetStyle={salesTargetStyle}
          salesTargets={salesTargets}
          value={third}
          monthIdx={2}
        />,
      },
    ];
  }, [type, months, overdue, first, second, third, sales_targets, client_rep_id]);

  const filteredJobs = React.useMemo(() => {
    return jobs.filter(j => {
      if (!search_query) { return true; }
      const searchQuery = search_query.toLowerCase();
      if (searchQuery.startsWith('#')) {
          return (j.job_number || '').toString().includes(searchQuery.slice(1))
              || (j.job_name || '').includes(searchQuery.slice(1));
      }
      return (j.client_name || '').toLowerCase().includes(searchQuery)
          || (j.client_rep_name || '').toLowerCase().includes(searchQuery)
          || (j.event_type || '').toLowerCase().includes(searchQuery)
          || (j.job_name || '').toLowerCase().includes(searchQuery)
          || (j.job_number || '').toString().toLowerCase().includes(searchQuery)
          || (j.month_inhandsdate || '').toLowerCase().includes(searchQuery)
          || (j.order_type || '').toLowerCase().includes(searchQuery)
          || (j.sales_rep_name || '').toLowerCase().includes(searchQuery)
          || (j.status_name || '').toLowerCase().includes(searchQuery);
    });
  }, [jobs, search_query]);

  React.useEffect(() => {
    setSkipPageReset(false);
  }, [filteredJobs]);

  React.useEffect(() => {
    rebuildTooltip();
  }, [type, months, overdue, first, second, third, sales_targets, client_rep_id]);

  const onClickRow = React.useCallback(handleOpenProjectMessagePopups, [handleOpenProjectMessagePopups]);

  const NoRowsFound = useCallback(() => {
    const styles = { textAlign: 'center', minHeight: 250, padding: 30, fontSize: 16 };
    if (type === 'task') {
      return <div style={styles}>There are no tasks available</div>;
    }

    return <div style={styles}>There are no projects available</div>;
  }, [type]);

  return (
    <div ref={containerRef}>
      <SimpleWindowedTableStyles rowClickable hoverRowBg bordered>
        <SimpleWindowedTable
          className="project-list"
          columns={columns}
          data={filteredJobs}
          defaultSort={{ id: 'date_inhandsdate', desc: true }}
          onClickRow={onClickRow}
          onScroll={rebuildTooltip}
          useTableProps={{
            autoResetPage: !skipPageReset,
            autoResetGroupBy: !skipPageReset,
            autoResetSortBy: !skipPageReset,
            autoResetFilters: !skipPageReset,
            autoResetRowState: !skipPageReset,
          }}
          height={getListContainerHeight()}
          tableHeaderProps={{
            style: {
              background: '#edf2f5',
              borderBottom: '1px solid #EDF2F4',
            },
          }}
          hideFooter={type !== 'sales'}
          itemSize={type === 'task' ? 120 : 70}
          NoRowsFound={NoRowsFound}
          clearRowFullWidth={windowWidth <= 1333}
        />
      </SimpleWindowedTableStyles>
    </div>
  );
}
